<template>
    <div>
        <el-tabs v-model="activeStoreId" @edit="handleTabsEdit" class="main">
          <!-- 门店导航 -->
          <el-tab-pane v-for="item in storeList" :key="item.id" :label="item.storeName" :name="item.id.toString()">
            <!-- {{item.content}} -->
          </el-tab-pane>
        </el-tabs>
        <!-- 头部操作按钮 -->
        <div class="header main">
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item class="formItems" label="分类类名:">
               <el-input placeholder="请输入分类名称" clearable v-model.trim="form.classifyName"></el-input>
            </el-form-item>
            <el-button class="formItems" type="primary" icon="el-icon-search" @click="queryClassify()">查询</el-button>
            <el-button class="formItems" type="info" icon="el-icon-refresh-right" @click="resetClassify()">重置</el-button>
            <el-button class="formItems" type="primary" icon="el-icon-plus" @click="addClassify()">新增分类</el-button>
            <el-button class="formItems bg-red" type="danger" icon="el-icon-delete-solid" @click="deleteClassify()">批量删除</el-button>
          </el-form>
        </div>
        <!-- 表格区 -->
        <div class="tableBox">
          <el-table
            :data="tableData"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            border
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="classifyName" label="名称" sortable width="180"></el-table-column>
            <el-table-column prop="isShow" label="状态" sortable width="180">
              <template slot-scope="scope"><span>{{scope.row.isShow == 1 ? '显示' : '隐藏'}}</span></template>
            </el-table-column>
            <!-- <el-table-column prop="date" label="排序" sortable width="180"></el-table-column> -->
            <el-table-column label="操作" sortable width="240">
              <template slot-scope="scope">
                <el-button type="text" @click="editClassify(scope.row)" icon="el-icon-edit" class="pdlr-24">编辑</el-button>
                <el-popover
                  placement="top"
                  width="240"
                  v-model="scope.row.visible"
                  v-if="scope.row.parentId > 0">
                  <p>确定删除<span class="warmWords">{{scope.row.classifyName}}</span>吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="cancleDelete(scope.row)">取消</el-button>
                    <el-button type="danger" size="mini" @click="deleteClassify(scope.row.id)">确定</el-button>
                  </div>
                  <el-button type="text" slot="reference"  icon="el-icon-edit" class="pdlr-24 warmWords">删除</el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import UploadPics from "@/components/UploadPics";
import { getStoreList } from "@/api/common.js"
import {
  treeGoodsClassifyList,
  subCateForm,
  deleteCate,
} from "@/api/online_department_store/categories_manage";
export default {
  components: {
    Pagination,
    UploadPics,
  },
  data() {
    return {
      storeList:[],//门店列表
      activeStoreId:'1',//门店导航默认id，1-江北、2-湖畔、3-香榭里、4-生活汇、5-香榭里
      form:{
        classifyName:''
      },
      tableData:[],
      multipleSelection:[],//选中列
    }
  },
  mounted() {
    this.getStore()
    this.getCate()
  },
  methods: {
    // 获取门店列表
    getStore(){
      getStoreList().then(res=>{
        console.log(res)
        this.storeList = res.data.body.list
      })
    },
    // 获取分类列表
    getCate(ret) {
      if (ret == 1) {
        this.pageNum = 1;
        this.pageSize = 10;
        this.classifyName = "";
      }
      let data = {
        // pageNum: this.pageNum,
        // pageSize: this.pageSize,
        classifyName: this.classifyName,
        goodsType: 3,
      };
      treeGoodsClassifyList(data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          // this.total = res.data.body.total;
          this.tableData = res.data.body
          // this.tableData.map((item) => {
          //   item.picture = this.picsPath(item.picture);
          // });
          this.tableData.forEach(e=>{
            e.visible = false
            if(e.children.length>0){
              e.children.forEach(s=>{
                s.visible = false
              })
            }
          })
        }
        console.log(this.tableData)
      });
    },
    handleTabsEdit(e){
      console.log(e)
      this.activeStoreId = e
    },
    // 查询
    queryClassify(){

    },
    // 重置查询条件
    resetClassify(){

    },
    // 新增品类按钮
    addClassify(){

    },
    // 删除品类
    deleteClassify(){

    },
    editClassify(e){

    },
    cancleDelete(e){
      e.visible = false
    },
    // 勾选交互事件
    handleSelectionChange(val){
      this.multipleSelection = val
    },
  },
};
</script>
<style lang="scss" scoped>
.formItems{
  margin: 12px 12px;
  float: left;
}
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
.bg-red {
  background: #f56c6c;
}
.el-form-item{
  width: 500px;
}
/deep/.el-tabs__nav-scroll {
  padding: 0 0 0 24px;
}
.pdlr-24{
  padding: 0 18px 0;
}
.warmWords{
  color: #f56c6c;
}
</style>

